export const validatePhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入手机'));
  } else {
    const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
    if (reg.test(value)) {
      return callback();
    }
    callback(new Error("请输入合法的手机号"));
  }
};
export const validateEmail = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入邮箱'));
  } else {
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (reg.test(value)) {
      return callback();
    }
    callback(new Error("请输入合法的邮箱"));
  }
};
export const validateCreditCode = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入统一社会信用代码'));
  } else {
    const reg = /^([0-9A-Za-z]{15})$/
    if (reg.test(value)) {
      return callback();
    }
    callback(new Error("请输入合法的统一社会信用代码"));
  }
};
export const validateTaxpayerNumber = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入纳税人识别号'));
  } else {
    const reg = /^[A-Z0-9]{15}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/
    if (reg.test(value)) {
      return callback();
    }
    callback(new Error("请输入合法的纳税人识别号"));
  }
};
export const validateBankNumber = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入账号'));
  } else {
    const reg = /^[1-9]\d{9,29}$/
    if (reg.test(value)) {
      return callback();
    }
    callback(new Error("请输入合法的账号"));
  }
};
export const validateBankConnectionNumber = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入账号'));
  } else {
    const reg = /^\d{3}\d{4}\d{4}\d{1}$/
    if (reg.test(value)) {
      return callback();
    }
    callback(new Error("请输入合法的账号"));
  }
};
export const validateUrl = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入网站'));
  } else {
    const reg = /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/
    if (reg.test(value)) {
      return callback();
    }
    callback(new Error("请输入合法的网站"));
  }
};
